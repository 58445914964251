import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";

const FeedbackPage = () => (
    <Layout>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfz5uDXJ0kzzSBNAMkWEwYP23SzF0tyP_m65zPlqbIoNeAlAw/viewform?embedded=true" width="640" height="598" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      <p> </p>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
  
  

export default FeedbackPage